import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const WhySection = () => {
  return (
    <div className="flex flex-col items-center pt-40 text-lg" id="why">
      <div>
        <h1 className="font-black text-4xl text-center my-6">
          Meet your new <br /> Frontend Engineer
        </h1>
        <article id="job" className="max-w-2xl mx-auto my-6 ">
          <p>Hi Olivia,</p>
          <p className="my-6">
            I am very excited to apply for the Frontend Engineer position at
            AccelByte! As a gamer, I am excited to contribute to a platform that
            empowers game studios to create exceptional games. I am particularly
            interested in learning about AccelByte's matchmaking services, as it
            was used by Predecessor during their early access phase.
          </p>
          <p className="my-6">
            In my current role as a senior front-end developer for an
            early-stage startup, I have developed the ability to work
            effectively with ambiguity. Sometimes, I receive a full spec with
            Figma design to build a feature, while other times, I am given just
            an idea and prototype details are left up to me. After completing
            the prototype, the CTO pitches it to the other executives, and if
            they approve it, we build the actual feature. Some of the prototypes
            that I built allowed them to close several deals worth well over
            $600K.
          </p>
          <p className="my-6">
            I also don’t give up easily. One example is I’ve fixed a dynamic
            scroll bug that had been in production for over a year, which my
            team had previously dismissed as impossible. While I did not waste
            time and stop working on other features and tasks, I spent 30
            minutes every day investigating and documenting every detail about
            the bug, which ultimately led to its resolution.
          </p>
          <p className="my-6">
            I would be thrilled to use these skills in the role of Frontend
            Engineer at AccelByte. Building multiple real-world apps in my
            previous roles has given me valuable experience in both making apps
            based on design specifications and creating prototypes from rough
            ideas.
          </p>

          <p className="my-6">
            I’m extremely excited about the opportunity to become a part of
            Accelbyte and I hope you’ll be just as happy to have me. Thank you
            very much for your consideration!
          </p>
          <p className="my-6"> Looking forward to hearing from you, </p>
          <p className="my-8"> Zeha Irawan </p>
        </article>
      </div>
    </div>
  )
}

export default WhySection
