import React from "react"

const WorkSampleSection = () => {
  return (
    <div
      id="work"
      className="flex flex-col items-center pt-24 max-w-3xl justify-center mx-auto"
    >
      <h1 className="font-bold text-4xl text-center">
        Things I've helped built
      </h1>
    </div>
  )
}

export default WorkSampleSection
