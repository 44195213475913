import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../custom/Navbar"
import ContactSection from "../custom/ContactSection"
import WhySection from "../custom/WhySection"
import ResumeSection from "../custom/ResumeSection"
import WorkSampleSection from "../custom/WorkSampleSection"

const SecondPage = () => {
  const buttonStyle = {
    backgroundColor: "tomato",
    color: "white",
    padding: "0.5rem 1rem",
    borderRadius: "0.5rem",
  }
  return (
    <Layout>
      <Seo title="Meet your new Front End Developesr" />
      <Navbar companyName="Basecamp" buttonStyle={buttonStyle} />
      <WhySection />
      <ResumeSection />
      <WorkSampleSection />
      <ContactSection buttonStyle={buttonStyle} />
    </Layout>
  )
}

export default SecondPage
