import React, { useEffect, useState } from "react"

const Navbar = ({ companyName, buttonStyle }) => {
  const isBrowser = () => typeof window !== "undefined"

  const detectScroll = () => {
    if (isBrowser) return window.pageYOffset
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [detectScroll])

  const [isScrolled, setIsScrolled] = useState(false)

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      if (!isScrolled) {
        setIsScrolled(true)
      }
    } else {
      if (isScrolled) {
        setIsScrolled(false)
      }
    }
  }

  return (
    <nav
      className={`fixed z-10 ${
        isScrolled ? "border-b-2" : "border-none"
      } w-full top-0 left-0 bg-white shadow-md`}
    >
      <ul className="flex gap-x-12  gap-y-6 flex items-center flex-col md:flex-row py-6 px-6 justify-end">
        <li className="text-xl hover:text-violet-500 hover:underline">
          <a href="#why">{`Cover Letter for ${companyName}`}</a>
        </li>
        <li className="text-xl hover:text-violet-500 hover:underline">
          <a href="#resume">Resume</a>
        </li>
        <li className="text-xl hover:text-violet-500 hover:underline">
          <a href="#work">Previous Work</a>
        </li>

        <li className="text-xl hover:text-violet-500 hover:underline">
          <button style={buttonStyle}>
            <a href="#contact">Get in touch</a>
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
