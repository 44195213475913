import React from "react"

const SectionItem = props => {
  return (
    <article>
      {props.heading && (
        <span className="font-black text-2xl my-6 block self-start">
          {props.heading}
        </span>
      )}
      <ul className="mt-12 text-left">
        <li className="flex justify-start gap-6">
          <p className="max-w-1/3">
            <strong>{props.leftTitle}</strong>
          </p>
          <div>
            <h3>
              <span className="mr-4">
                <strong>{props.organization}</strong>
              </span>
              {props.location}
            </h3>
            <p className="mt-2">{props.description}</p>
            <ul className="list-disc mt-4 flex flex-col gap-4">
              {props.items.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </li>
      </ul>
    </article>
  )
}

export default SectionItem
