import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SectionItem from "./SectionItem"

const ResumeSection = () => {
  return (
    <div
      id="resume"
      className="flex flex-col items-center pt-24 max-w-3xl justify-center mx-auto"
    >
      <h1 className="font-bold text-4xl text-center">
        Experienced to create awesome <br /> web app
      </h1>

      <SectionItem
        heading="Work"
        leftTitle="April 2022 to Present"
        organization="Finitive LLC"
        location="Remote · USA"
        description="Intelligent Credit Marketplace with $3.5B capital commitments"
        items={[
          `Worked directly with the CTO`,
          `Scheduling & availability features for investors & borrowers, similar to Calendly.`,
          `User invite feature for internal & external users, similar to Jira.`,
          `Management console feature for admin to manage users, teams & company.`,
          `Improve performance to 10% faster by refactoring codes and fixing bugs.`,
          `Wrote unit tests with Jest and React Testing Library.`,
        ]}
      />

      <SectionItem
        leftTitle="December 2020 to February 2022"
        organization="Aha Learn-to-Earn LLC"
        location="Remote · USA"
        description="Exam prep with crypto incentive"
        items={[
          `Led and mentored a team of 4 junior developers.`,
          `Sped up development time by 200% by switching to Tailwind instead of Material UI.`,
          `Built 100+ landing pages based on Figma design with a 40+% average conversion rate.`,
          `Grew the app to 6000 users.`,
          `Pitched ideas to the founders that are being used as a core marketing strategy and built prototypes for them.`,
          `Created a design system with Storybook to make it easier for designers and developers to work together.`,
          `Implemented accurate tracking & analytics with Google Analytics 4 data layer to figure out which marketing channels work best.`,
        ]}
      />

      <SectionItem
        leftTitle="December 2019 to March 2020"
        organization="Igaku PTE. LTD"
        location="Remote · Singapore"
        description="Telehealth Medical directory"
        items={[
          `Built and maintained both the Front-End and Back-End of the web application for booking teleconsultation with a doctor.`,
          `Dockerized the app and deployed it to AWS.`,
          `Implemented video call feature using Twilio in less than 2 weeks.`,
          `Built a mobile app using react native and integrated it with Twilio.`,
        ]}
      />

      <SectionItem
        heading="Education"
        leftTitle="January 2018 to March 2019"
        organization="Microverse"
        location="Remote · USA"
        description="Remote Full Stack Web Development Program"
        items={[
          `Spent 1300+ hours mastering algorithms, data structures, and
                full-stack development while simultaneously developing projects
                with Ruby, Rails, JavaScript, React, and Redux.`,
          `Developed skills in remote pair programming using GitHub, industry-standard git-flow, and daily standups to communicate
                and collaborate with international remote developers.`,
          `Provided both synchronous and asynchronous guidance and
                assistance to 3 mentees.`,
        ]}
      />
    </div>
  )
}

export default ResumeSection
