import React from "react"

function ContactSection(props) {
  return (
    <div
      id="contact"
      // className="bg-theme rounded-tl-5xl pt-24 pb-12 px-6 text-white bg-[url('../images/contact.svg')] bg-no-repeat bg-cover bg-origin-content"
      className="py-12"
    >
      <div className="lg:w-2/5 mx-auto flex flex-col justify-center items-center gap-y-6">
        <h1 className="font-bold text-4xl mt-12">Ready to get in touch?</h1>

        <button style={props.buttonStyle}>
          <a href="mailto:hello@zehairawan.com?subject=Hello!">Contact Me</a>
        </button>

        <div className="text-center">
          <p>Email: hello@zehairawan.com</p>
          <p>Call : +62813-3506-9933</p>
        </div>
      </div>
    </div>
  )
}

export default ContactSection
